/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable security/detect-object-injection */
import type {
  BatchAutoRequest,
  BatchManualRequest,
  BatchReportResponse,
  ScoreSettings,
} from '../../types/batch-reports';
import { ReportTypeEnum } from '../../types/enums';
import {
  BatchLoanUploadRequest,
  SingleLoanUploadRequest,
} from '../../types/portfolio';
import type { CsvReport, CsvReportUploadHeaders } from '../../types/report';
import { EntityADS, EntityMDI } from '../../types/uploads';
import { getCurrencyByIsoCode } from '../settings/settings.settings';
import { ISO } from './constants';
import {
  isCsvDataBatchLoan,
  isCsvDataSingleLoan,
  makeEntitiesForBatchLoan,
  makeEntitiesForCompanyLoans,
} from './portfolio-report-helpers';
import { makeUploadReportReqBody } from './report-helpers';

type UploadRequest =
  | BatchAutoRequest
  | BatchManualRequest
  | BatchLoanUploadRequest
  | SingleLoanUploadRequest;

export const makeEntitiesForBatchMdi = (
  csvData: CsvReport,
  csvValues: string[][]
): EntityMDI[] => {
  // console.log('csvValues', csvValues);
  // map unique company names as properties and set the index occurrences as its values
  // eg { 'Company 1': [1,3,6,9] <- index used later }
  const companyNameAndIndexes: Record<string, number[]> = {};

  let nameProperty = csvData.details_name;

  if ('company_name' in csvData) {
    nameProperty = csvData.company_name;
  }

  nameProperty?.forEach((name, i) => {
    let arr = [];
    for (let j = 0; j < nameProperty.length; j++) {
      if (name === nameProperty[j]) {
        arr.push(j);
      }
    }
    companyNameAndIndexes[name] = arr;
  });

  const companyNameAndDataMap: Record<
    string,
    {
      [key in CsvReportUploadHeaders]: string[];
    }
  > = {};

  // for each company, iterate over the indexArr and use that index to get the correct data
  Object.entries(companyNameAndIndexes).forEach(
    ([companyName, indexArr], i) => {
      // iterate through the csvData's properties (details_name, currency etc)
      Object.entries(csvData).forEach(([header, values]) => {
        // and use the index to get the right value
        // indexArr => [1,4,6,8]
        const companyValues = indexArr.map(index =>
          values[index] ? values[index] : null
        );

        companyNameAndDataMap[companyName] = {
          ...companyNameAndDataMap[companyName],
          [header]: companyValues,
        };
      });
    }
  );

  // the final step is to use the  filtered csvValues with the makeUploadReportReqBody used for report upload
  // this creates the entity field required by the API
  const entities = Object.entries(companyNameAndDataMap).map(
    ([key, company]) => {
      const filteredCsv = csvValues.filter(c => c.includes(key));
      return makeUploadReportReqBody(company, filteredCsv);
    }
  );

  return entities;
};

export const makeEntitiesForBatchAds = (
  csvData: CsvReport,
  currency: string | undefined
) => {
  return csvData?.company_id?.map(
    (id, i) =>
      ({
        company_id: id?.trim().replace(/"/g, ''),
        iso_code: csvData.iso_code[Number(i)]?.trim().replace(/"/g, ''),
        currency:
          !!currency && currency !== ISO
            ? currency
            : getCurrencyByIsoCode(csvData.iso_code[Number(i)]?.trim()),
      } as EntityADS)
  );
};

interface ConvertCsvProps {
  csvData: CsvReport;
  csvValues: string[][];
  name: string;
  uploadType: ReportTypeEnum;
  accounts_type?: number;
  currency?: string;
  productType?: string;
  score_settings?: ScoreSettings;
}

export const convertCSVToRequestBody = ({
  csvData,
  csvValues,
  name,
  uploadType,
  accounts_type = 1,
  currency,
  productType,
  score_settings,
}: ConvertCsvProps): UploadRequest => {
  // BASIC INFO
  let response = {
    name: name,
    accounts_type,
    // currency,
    entities: [],
    product_type: productType,
    score_settings,
  } as UploadRequest;

  if (uploadType === ReportTypeEnum.BATCH_LOAN && isCsvDataBatchLoan(csvData)) {
    // check type of csvData
    const entities = makeEntitiesForBatchLoan(csvData);

    return {
      entities,
    };
  }

  if (
    uploadType === ReportTypeEnum.SINGLE_LOAN &&
    isCsvDataSingleLoan(csvData)
  ) {
    const entities = makeEntitiesForCompanyLoans(csvData);

    return {
      entities,
    };
  }

  if (uploadType === ReportTypeEnum.BATCH_ADS) {
    const entities = makeEntitiesForBatchAds(csvData, currency);
    response.entities = entities || [];
  }

  if (uploadType === ReportTypeEnum.BATCH_MDI) {
    const entities = makeEntitiesForBatchMdi(csvData, csvValues);
    response.entities = entities || [];
  }

  return response;
};

function calculateHoursBetweenDates(begin: Date | string, end: number) {
  const date1 = new Date(begin);
  const date2 = new Date(end);
  const diff = (date2.getTime() - date1.getTime()) / 1000;
  return diff / 3600;
}

export const isJobFailed = (job: BatchReportResponse) => {
  return (
    (job.created_at !== job.updated_at || job.finished_at) &&
    job.total_reports === job.failed_reports &&
    !job.cancelled_at
  );
};

export const isJobComplete = (job: BatchReportResponse) => {
  return (
    job.total_reports !== job.failed_reports &&
    job.total_reports !== null &&
    job.finished_at &&
    !job.cancelled_at
  );
};

export const isJobPending = (job: BatchReportResponse) => {
  return (
    !job.finished_at &&
    (job.failed_reports === null || job.failed_reports === 0) &&
    calculateHoursBetweenDates(job.created_at, Date.now()) < 24 &&
    !job.cancelled_at
  );
};

export const isJobCancelled = (job: BatchReportResponse) => {
  return !!job.cancelled_at;
};
